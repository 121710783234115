<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="col-sm-12"><h1>Do you want to remove this item?</h1></div>
        <button class="btn btn-danger" @click="deletePassenger">Yes</button>
        <a class="btn btn-primary" href="/partner/passenger/list">No</a>
      </div>
    </div>
  </div>
</template>

<script>
//eslint-disable-next-line
import Cookies from 'js-cookie';
export default {
  name: 'PassengerDelete',
  methods: {
    deletePassenger: async function () {
      let self = this;
      // console.log("state $store ", this.$store.state)
      let id = this.$route.params.id;
      let apiUrl = process.env.VUE_APP_API_URL;
      let token = this.$store.state.token;
      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      await fetch(apiUrl + 'partner/passenger/delete', {
        method: 'POST',
        headers: headersObject,
        body: 'id=' + encodeURI(id),
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          Cookies.set('actionType', 'deleted');
          self.$router.push('/partner/passenger/list');
        })
        .catch(function (err) {
          console.log('error', err);
        });
    },
  },
};
</script>

<style scoped></style>
